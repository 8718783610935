<template>
  <div id="home">
    <HeroSlider
      v-if="heroSliderContent && heroSliderContent.active && !isHeroVideo"
      :slider-data="heroSliderContent.data"
    />
    <HeroVideo
      v-else-if="heroSliderContent && heroSliderContent.active && isHeroVideo"
      :video="heroVideo"
      :image="heroImage"
      :slider-data="heroSliderContent.data"
    />
    <VerticalLabel />
    <PageGtm />
    <PageTrackingGtm v-if="metaTitle" :page-title-gtm="metaTitle" />
    <LazyHydrate when-visible>
      <GenresBlock
        v-if="genresBlockContent && genresBlockContent.active"
        :blockData="genresBlockContent.data"
      />
    </LazyHydrate>
    <LazyHydrate when-visible>
      <FeaturedProductSlider
        v-if="featuredProductsContent"
        :featured-products-content="featuredProductsContent"
      />
    </LazyHydrate>
    <LazyHydrate when-visible>
      <MagazineSlider />
    </LazyHydrate>
    <LazyHydrate when-visible>
      <SeoText
        v-if="seoTextContent && seoTextContent.active"
        :seo-text="seoTextContent.text"
      />
    </LazyHydrate>
    <LazyHydrate when-visible>
      <Inspired
        v-if="inspiredBlockContent && inspiredBlockContent.active"
        :block-data="inspiredBlockContent.data"
      />
    </LazyHydrate>
    <Organization />
    <Website />
  </div>
</template>

<script type="module">
import {
  computed,
  defineComponent,
  useContext,
  useFetch,
  ref,
  onMounted,
} from '@nuxtjs/composition-api';
import { useCache, CacheTagPrefix } from '@gemini/vsf-cache';
import PageGtm from '~/components/Gtm/PageGtm.vue';
import PageTrackingGtm from '~/components/Gtm/PageTrackingGtm.vue';
import HeroSlider from '~/components/Homepage/HeroSlider/HeroSlider.vue';
import HeroVideo from '~/components/Homepage/HeroSlider/HeroVideo.vue';
import Inspired from '~/components/Homepage/Inspired.vue';
import LazyHydrate from 'vue-lazy-hydration';
import GenresBlock from '~/components/Homepage/GenresBlock.vue';
import SeoText from '~/components/Homepage/SeoText.vue';
import FeaturedProductSlider from '~/components/Homepage/FeaturedProductSlider/FeaturedProductSlider.vue';
import { contentGetters, useContent } from '~/composables';
import VerticalLabel from '~/components/Homepage/VerticalLabel.vue';
import { useGetAlternateMatching } from '~/helpers/alternate/getAlternateMatching';
import { isClient } from '@storefront-ui/vue/src/utilities/helpers';
import Organization from '~/components/StructuredData/Organization.vue';
import Website from '~/components/StructuredData/Website.vue';
import { handleSeo } from '~/helpers/seo/handleSeo';
import MagazineSlider from '~/components/CMS/PayloadComponents/MagazineSlider.vue';

export default defineComponent({
  name: 'HomePage',
  components: {
    MagazineSlider,
    Website,
    Organization,
    HeroSlider,
    HeroVideo,
    PageGtm,
    PageTrackingGtm,
    Inspired,
    LazyHydrate,
    GenresBlock,
    SeoText,
    FeaturedProductSlider,
    VerticalLabel,
  },
  setup() {
    const { addTags } = useCache();
    const { app } = useContext();
    const localeCode = app.i18n.localeProperties.localeValue;
    const { blocks, loadBlocks } = useContent();
    const inspiredBlockSelector = `inspired-${localeCode}`;
    const inspiredBlockContent = ref();
    const genresBlockSelector = `genres-${localeCode}`;
    const genresBlockContent = ref();
    const heroSliderSelector = `hero-slider-${localeCode}`;
    const heroSliderContent = ref();
    const seoTextSelector = `seo-text-${localeCode}`;
    const seoTextContent = ref();
    const featuredProductsSelector = `hp-featured-products-${localeCode}-women`;
    const featuredProductsContent = ref();

    const isHeroVideo = ref(false);
    const heroVideo = ref({});
    const heroImage = ref({});

    const { getAlternatePageArray } = useGetAlternateMatching();
    const refDataSeoAlternate = ref([]);
    refDataSeoAlternate.value = getAlternatePageArray();

    const { getCanonical } = handleSeo();
    const canonicalLink = computed(() => getCanonical());

    const metaTitle = computed(() =>
      app.i18n.t('Divo Boutique ⋄ Fashion and Luxury Official Online Store')
    );
    const metaDescription = computed(() =>
      app.i18n.t(
        'Enter Divo Boutique online store and discover men, women and kids’ collections designed by the most exclusive fashion brands. Enjoy your shopping in the official shop.'
      )
    );

    onMounted(async () => {
      if (isClient) {
        try {
          await loadBlocks({
            identifiers: [
              inspiredBlockSelector,
              seoTextSelector,
              featuredProductsSelector,
            ],
          });
          const blockData = await contentGetters.getCmsBlockContent(
            blocks.value
          );
          inspiredBlockContent.value = blockData[inspiredBlockSelector];
          seoTextContent.value = blockData[seoTextSelector];
          featuredProductsContent.value = blockData[featuredProductsSelector];
        } catch {
          console.warn('Could not get blockConstantName block content');
        }
      }
      heroVideo.value = heroSliderContent.value?.data?.slides.find((slide) => {
        return slide.isVideo === true;
      });
      heroImage.value = heroSliderContent.value?.data?.slides.find((slide) => {
        return slide.isVideo === false;
      })?.image;
      isHeroVideo.value =
        heroSliderContent.value?.data?.slides.filter((slide) => {
          return slide.isVideo === true;
        }).length > 0;
    });

    useFetch(async () => {
      try {
        await loadBlocks({
          identifiers: [genresBlockSelector, heroSliderSelector],
        });
        const blockData = await contentGetters.getCmsBlockContent(blocks.value);
        heroSliderContent.value = blockData[heroSliderSelector];
        genresBlockContent.value = blockData[genresBlockSelector];
      } catch {
        console.warn('Could not get blockConstantName block content');
      }
      addTags([{ prefix: CacheTagPrefix.View, value: 'homepage' }]);
      heroVideo.value = heroSliderContent.value?.data?.slides.find((slide) => {
        return slide.isVideo === true;
      });
      heroImage.value = heroSliderContent.value?.data?.slides.find((slide) => {
        return slide.isVideo === false;
      })?.image;
      isHeroVideo.value =
        heroSliderContent.value?.data?.slides.filter((slide) => {
          return slide.isVideo === true;
        }).length > 0;
    });

    return {
      metaTitle,
      metaDescription,
      inspiredBlockContent,
      genresBlockContent,
      heroSliderContent,
      seoTextContent,
      featuredProductsContent,
      refDataSeoAlternate,
      canonicalLink,
      heroVideo,
      heroImage,
      isHeroVideo,
    };
  },
  head() {
    return {
      title: this?.metaTitle,
      meta: [
        { hid: 'robots', name: 'robots', content: 'index, follow' },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this?.metaDescription,
        },
        {
          hid: 'description',
          name: 'description',
          content: this?.metaDescription,
        },
        { hid: 'og:title', property: 'og:title', content: this?.metaTitle },
      ],
      // eslint-disable-next-line no-unsafe-optional-chaining
      link: [...this?.refDataSeoAlternate, this?.canonicalLink],
    };
  },
});
</script>

<style lang="scss" scoped>
#home {
  box-sizing: border-box;
  @include for-desktop {
    @include desktop-boxed;
    padding: 0;
    margin: 0 auto;
  }
}
.hero-section {
  margin: var(--spacer-xl) auto var(--spacer-lg);
  ::v-deep .sf-link:hover {
    color: var(--c-white);
  }
  @include for-desktop {
    margin: var(--spacer-xl) auto var(--spacer-2xl);
  }
}
.call-to-action {
  background-position: right;
  margin: var(--spacer-xs) 0;
  @include for-desktop {
    margin: var(--spacer-xl) 0 var(--spacer-2xl) 0;
  }
}
.products {
  margin-top: var(--spacer-base);
}
</style>
